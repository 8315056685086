<template>
  <div class="product-detail">
    <van-nav-bar title="商品详情" left-arrow fixed @click-left="goBack()" />
    <div class="contents" :class="{'maginTop': true}">
      <div class="title-d">{{product.title}}</div>
      <div class="product-detail-d">
        <div class="product-img">
          <img :src="product.listImg" alt />
        </div>
        <div class="product-info">
          <div class="price">¥ {{product.price}}</div>
          <div class="price" v-if="product.volume === 'Y'">积分: {{product.volumeCount}}</div>
          <div class="note">{{product.note}}</div>
        </div>
      </div>
    </div>
    <div class="desc">
      <div class="nav">商品详情</div>
      <div class="desc-info" v-html="product.detail">
        <!-- <img src="../../assets/快POS.jpg" alt /> -->
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="shopIsShow = true">立即申请</div>
    </div>
    <div class="shop" v-if="shopIsShow">
      <div class="back" @click="shopIsShow =false"></div>
      <div class="shop-content">
        <div class="shop-info">
          <div class="close" @click="shopIsShow =false">X</div>
          <div class="shop-top">
            <div class="shop-img">
              <img :src="product.mainImg" alt />
            </div>
            <div class="shop-detail">
              <div class="price">¥ {{product.price}}</div>
              <div class="stock">
                <span v-if="product.stock > 0">库存{{product.stock}}件</span>
                <span v-if="product.stock <= 0" style="color:red;">库存不足</span>
              </div>
            </div>
          </div>
          <div class="nums">
            <label class="lab">申请数量</label>
            <div class="num-c">
              <div class="remove" @click="removeNum">——</div>
              <input
                type="number"
                v-model="num"
                @change="quantityChange"
                :disabled="product.stock <= 0"
              />
              <div class="add" @click="addNum">+</div>
            </div>
          </div>
          <div
            class="confrm"
            :class="{'confrm-dis': product.stock <= 0}"
            @click="confrmOk"
          >{{product.stock > 0 ? "确认" : "缺货"}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from "../../api/product";
export default {
  name: "ProductDetail",
  data() {
    return {
      product: {},
      shopIsShow: false, // 是否显示商品确认窗口
      num: 1 // 商品数量
    };
  },
  created() {
    this.getData(this.$route.params.id);
    // console.log('this.$route = ', this.$route);
    // 清除缓存
    window.$tempAddressId = undefined;
    window.$tempRemark = undefined;
    window.$tempQuantity = undefined;
  },
  methods: {
    async getData(id) {
      const res = await getDetail(id);
      if (res && res.code !== 0) {
        this.$router.goBack(false);
        return;
      }
      this.product = res.data;
      // this.product.stock = 0;
      // 起定量
      this.num = this.product.moq;
      console.log("this.product  = ", this.product);
    },
    removeNum() {
      if (this.num <= this.product.moq) return;
      if (this.num === 1) return;
      this.num -= this.product.moq;
    },
    addNum() {
      if (this.product.stock <= 0) return;
      if (this.num >= this.product.stock) return;
      if (this.num === 999) return;
      this.num += this.product.moq;
    },
    quantityChange() {
      if (this.num > 9999) {
        this.num = 9999;
      }
      if (this.num <= this.product.moq) {
        this.num = this.product.moq;
      }
      if (this.num % this.product.moq !== 0) {
        const res = parseInt(this.num / this.product.moq);
        console.log("res = " + res);
        this.num = this.product.moq * (res !== 0 ? res : 1);
      }
    },
    confrmOk() {
      if (this.product.stock <= 0) {
        return;
      }
      this.$router.push({
        path: "/mall/settlement",
        query: { productId: this.product.id, quantity: this.num }
      });
    },
    goBack() {
      this.$router.goBack();
      // this.$router.back();

    }
  }
};
</script>

<style lang="scss">
.product-detail {
  position: relative;
  width: 100;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  .contents {
    background-color: #fff;
    margin-bottom: 10px;
    // margin-top: 46px;
  }
  .product-img {
    background-color: #fff;
    img {
      border-radius: 10px;
      width: 90%;
      margin: auto 5%;
    }
  }
  .product-info {
    background-color: #fff;
    padding: 10px 5%;
    .price {
      color: rgb(240, 128, 96);
      font-weight: bold;
      font-size: 20px;
    }
    .note {
      width: 100%;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // -o-text-overflow: ellipsis;
      // overflow: hidden;
      margin-top: 10px;
    }
  }
  .title-d {
    padding: 10px 5%;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
  }
  .desc {
    background: #fff;
    margin-bottom: 60px;
    .nav {
      line-height: 50px;
      padding-left: 5%;
    }
    .desc-info {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    .btn {
      width: 80%;
      text-align: center;
      // margin: auto 10%;
      background: #eb815f;
      padding: 10px 0;
      color: #fff;
      border-radius: 5px;
    }
  }
  .shop {
    position: fixed;
    z-index: 30;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(155, 155, 155, 0.3);
  }
  .back {
    width: 100%;
    height: 50%;
  }
  .shop-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: #fff;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 18px;
    font-size: 14;
    color: #e0dddd;
  }

  .shop-info {
    .shop-img,
    img {
      width: 60px;
      height: 60px;
    }
    .shop-detail {
      margin-left: 30px;
    }
    .price {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .stock {
      font-size: 11px;
      color: #cecece;
    }
  }
  .shop-top {
    display: flex;
    padding: 20px 20px;
    border-bottom: 1px solid #e3e3e3;
  }
  .nums {
    display: flex;
    padding: 20px 20px;
    align-items: center;
    justify-content: space-between;
    .lab {
      font-size: 14px;
    }
    .num-c {
      display: flex;
      .add {
        font-size: 16px;
        font-weight: bold;
      }
      .remove {
        font-size: 16px;
        font-weight: bold;
        width: 10px;
        overflow: hidden;
      }
      input {
        display: inline-block;
        width: 50px;
        background: #f0f0f0;
        height: 20px;
        margin: 0 20px;
        border: none;
        text-align: center;
      }
    }
  }
  .confrm {
    position: absolute;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    background: #eb815f;
    color: #fff;
  }
  .confrm-dis {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
